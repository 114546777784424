/* RESET CSS  */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* GLOBALS  STYLE */
.App {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #282c34;
  /*flex-wrap: wrap;*/
  flex-direction: column;
}

.App-link {
  color: #61dafb;
}
/* CARD STYLE */
.card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.card-container {
  background: #fff;
  width: 8rem;
  height: 12rem;
  border-radius: 7px;
  margin: 10px;
}

.board {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.play-board{
  display: flex;
  flex-direction:column;
}

.hand {
  display: flex;
  flex-direction: row;
}

.card-content__inner,
.card-content-inner__border-top,
.card-content-inner__border-bottom {
  width: 90%;
}
.card-content-inner__border-top,
.card-content-inner__border-bottom {
  height: 10%;
}
.card-content__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: inherit;
  border-color: black;
}
.card-content-inner__border-top,
.card-content-inner__border-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
}

.card-content-inner__center {
  width: 100%;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1rem;
  font-size: 66px;
}
.top-symbol-sigle,
.bottom-symbol-sigle {
  text-align: center;
  width: 100%;
}

.top-symbol,
.bottom-symbol {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.top-symbol {
  align-items: flex-start;
}
.bottom-symbol {
  align-items: flex-end;
}
.center-symbol-sigle {
  font-size: 72px;
}

/* DYNAMIC COLOR CLASS SECTION */
.red {
  color: red;
}
.black {
  color: black;
}

/* BUTTON SECTION
button {
  background: none;
  border: 1px solid #fff;
  outline: none;
  margin: 0.1rem;
  padding: 0.4rem;
  font: inherit;
  font-size: 0.75rem;
  line-height: 1;
  color: #fff;
  cursor: pointer;
}
button:hover {
  background-color: #fff;
  color: #444;
}
*/
